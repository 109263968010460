import React, {
  FC,
  useState,
  useMemo,
  useContext,
  useEffect,
  useRef,
  memo
} from "react";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import { CountrySiteContext } from "customProviders/CountrySiteProvider";
import { getLanguageFromUrl } from "@RHCommerceDev/utils/getCountryFromUrl";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import analyticsLoader from "@RHCommerceDev/analytics/loader";

export type CountryCheckerDialogProps = {
  open?: boolean;
  onClose?: () => void;
  country?: string;
  language?: string;
  handleSubmit?: (country: string) => void;
  className?: string;
  breakPoint?: number;
};

// TODO: move to its own component
const CustomDropdown = ({ selectedOption, onSelect, label, options }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <div className="relative">
        <label className="absolute -top-2 left-2 bg-white px-1 text-[#666666] text-[11px] z-50 font-primary-rhlight">
          {label}
        </label>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="!border-[#666] !p-3 !bg-transparent !flex !justify-between !items-center !text-lg !w-full"
          style={{
            borderBottom: isOpen ? "0" : "0.5px solid #666",
            borderLeft: isOpen ? "0.5px solid #666" : "0.5px solid #666",
            borderRight: isOpen ? "0.5px solid #666" : "0.5px solid #666",
            borderTop: isOpen ? "0.5px solid #666" : "0.5px solid #666"
          }}
        >
          {selectedOption && (
            <span className="text-[13px] text-black">
              {selectedOption.label}
            </span>
          )}
          <svg
            className={`w-5 h-5 transform transition-transform duration-300 ${
              isOpen ? "rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="overflow-y-hidden overyflow-x-hidden absolute w-full max-h-80 overflow-auto z-40 -top-0 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]">
          <div className="overflow-y-hidden overflow-x-hidden border-y-[0.5px] border-x-[0.5px] border-[#999] bg-white ">
            {options.map(option => (
              <button
                key={option.value}
                className="w-full p-2 m-1 text-left text-xs flex justify-between items-center border-0 bg-transparent"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSelect(option);
                  setIsOpen(false);
                }}
              >
                <span className="text-[13px] text-black font-primary-rhlight font-light">
                  {option.label}
                </span>
                {option.value === selectedOption.value && (
                  <svg
                    className="w-5 h-5 text-black text-xs"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const CountryCheckerDialog: FC<CountryCheckerDialogProps> = () => {
  const env = useEnv();
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const { pageContent } = useFetchModel("admin/home", true);
  const { open, onClose, onSelectCountry, currentCountry } =
    useContext(CountrySiteContext);
  const [selectedCountry, setSelectedCountry] =
    useState<{ label: string; value: any }>();
  const mappedLanguage = getLanguageFromUrl().mapped;
  const countries = useMemo(() => {
    const baseCountries = [
      { label: "United States ($)", value: "US" },
      { label: "Belgium (€)", value: "BE" },
      { label: "Canada (C$)", value: "CA" },
      { label: "Germany (€)", value: "DE" },
      { label: "Spain (€)", value: "ES" },
      { label: "United Kingdom (£)", value: "GB" }
    ];
    if (yn(env.FEATURE_EU_EXPANSION_FR)) {
      baseCountries.push({ label: "France (€)", value: "FR" });
    }
    return baseCountries;
  }, [env.FEATURE_EU_EXPANSION_FR]);

  const handleClickOutside = event => {
    if (dialogRef.current && !dialogRef.current?.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (!selectedCountry && currentCountry) {
      const matchedCountry = countries.find(
        country => country.value === currentCountry
      );
      matchedCountry && setSelectedCountry(matchedCountry);
    }
  }, [selectedCountry, currentCountry, countries]);

  useEffect(() => {
    if (!processEnvServer && open) {
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector<HTMLInputElement>("#spa-root > *")!,
          a.EVENTS.COUNTRY_SWITCHER_POPUP.INT_TYPE
        )
      );
    }
  }, [open]);

  return (
    <>
      {open && (
        <div className="overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-[9999] flex items-center justify-center content-center align-middle h-screen w-screen bg-gray-500 bg-opacity-50 transition-opacity">
          <div
            className="shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] relative bg-white w-[296px] h-auto md:w-[376px] md:h-auto p-12 shadow-lg justify-center"
            ref={dialogRef}
          >
            <div className="absolute flex justify-end top-0 right-0">
              <button
                onClick={onClose}
                className="h-[48px] w-[48px] p-0 text-black hover:text-gray-500 bg-transparent border-none justify-center content-center align-middle items-center flex"
              >
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Icon/Close">
                    <g id="Close vector">
                      <path
                        id="Vector 249"
                        d="M0.5 1.16211L16.5 17.1621"
                        stroke="black"
                      />
                      <path
                        id="Vector 250"
                        d="M0.5 17.1621L16.5 1.16211"
                        stroke="black"
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </div>

            <h2 className="font-primary-ultra-thin text-[16px] md:text-[20px] font-light text-center m-0 leading-[1.15]">
              {pageContent?.PLEASE_SELECT_REGION || "PLEASE SELECT A REGION"}
            </h2>

            <p className="text-[13px] font-primary-rhthin tracking-[0.195px] text-center text-black mb-6 leading-[16.25px]">
              {pageContent?.You_are_visiting_country ||
                `You are visiting an RH site that does not match your current
              location. For a localized experience, please choose your preferred
              country below.`}
            </p>

            <div className="mb-8">
              <CustomDropdown
                selectedOption={selectedCountry}
                onSelect={setSelectedCountry}
                options={countries}
                label={
                  pageContent?.Choose_Your_Country || "Choose Your Country"
                }
              />
            </div>

            <button
              onClick={() => {
                if (!processEnvServer) {
                  document?.body?.dispatchEvent(
                    new CustomEvent("cta_click", {
                      detail: {
                        item: {
                          popUpSwitch: true,
                          country: selectedCountry?.value,
                          language: mappedLanguage
                        }
                      }
                    })
                  );
                }
                selectedCountry && onSelectCountry(selectedCountry.value);
              }}
              disabled={!selectedCountry}
              className="w-full h-[48px] bg-black text-white text-[11px] font-light font-primary-rhsans leading-[13.20px] tracking-normal py-3 hover:bg-gray-800 transition duration-300"
            >
              {pageContent?.CONFIRM || "CONFIRM"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(CountryCheckerDialog);
