import React, { FC } from "react";
import { Typography } from "@RHCommerceDev/utils/material-ui-core";

export interface TradeRequestDialogConfirmationProps {
  onDoneClicked?: (
    event: React.MouseEvent,
    reason: "backdropClick" | "escapeKeyDown"
  ) => void;
  success?: string;
  successHeading?: string;
}

export const TradeRequestDialogConfirmation: FC<
  TradeRequestDialogConfirmationProps
> = ({ onDoneClicked, success, successHeading }) => {
  return (
    <>
      <Typography
        variant="h2"
        dangerouslySetInnerHTML={{ __html: successHeading ?? "" }}
      />
      <Typography
        paragraph
        dangerouslySetInnerHTML={{ __html: success ?? "" }}
      />
    </>
  );
};

export default TradeRequestDialogConfirmation;
