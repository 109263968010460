import React, { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useTheme
} from "@RHCommerceDev/utils/material-ui-core";
import { DialogProps } from "@material-ui/core/Dialog";
import Promo from "@RHCommerceDev/component-promo";
import RHCloseIcon from "@RHCommerceDev/icon-close";

export interface PromosDialogProps extends Omit<DialogProps, "children"> {
  data: PromoDisplay[];
  onEnd?: () => void;
}

export const PromosDialog: FC<PromosDialogProps> = ({
  data = [],
  onEnd,
  onClose
}) => {
  const theme = useTheme();
  const promoData: PromoDisplay[] = data.filter(d => d);

  return (
    <>
      <DialogTitle disableTypography>
        <IconButton
          disableRipple
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            color: theme.palette.common.white
          }}
          onClick={event => !!onClose && onClose(event, "escapeKeyDown")}
        >
          <RHCloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          padding: 0
        }}
      >
        {promoData.map(item => (
          <Promo key={`promo-${item.id}`} data={item} onEnd={onEnd} />
        ))}
      </DialogContent>
    </>
  );
};

PromosDialog.defaultProps = {};

export default ({ data, ...rest }: PromosDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog
      {...rest}
      PaperProps={{
        style: {
          background: theme.palette.common.black
        }
      }}
      fullScreen
    >
      <PromosDialog data={data} {...rest} />
    </Dialog>
  );
};
