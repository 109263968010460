import React, { FC } from "react";
import {
  Button,
  Typography,
  Grid
} from "@RHCommerceDev/utils/material-ui-core";
import { useTheme } from "@material-ui/core";
import classNames from "classnames";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import he from "he";
import { usePageContent } from "customProviders/LocationProvider";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import { useKeycloak } from "@RHCommerceDev/utils/Keycloak/KeyCloak";
import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";
import { getPriceUserType } from "@RHCommerceDev/component-product-grid";
import { SpecialOrderConfirmationProps } from "./type";

export const SpecialOrderConfirmation: FC<SpecialOrderConfirmationProps> = ({
  onConfirmed,
  salePriceLabel = "Sale",
  SPOAddonVariables,
  setAddedFromPanel
}) => {
  const theme = useTheme();

  const typographyClasses = useTypographyStyles({
    keys: [
      "skuListSpo",
      "skuListColumn1",
      "skuListColumn2",
      "rhBaseH3",
      "uppercaseText",
      "reImagineBody1"
    ]
  });

  const { keycloak } = useKeycloak();
  const { userType } = useRhUserAtomValue();

  const { pageContent } = usePageContent();
  const SPOConfirmationText = SPOAddonVariables?.filter(
    spoItem => spoItem?.spo === true
  )?.[0];

  const spoConfirmOnClick = e => {
    onConfirmed?.(e);
    setAddedFromPanel?.(false);
  };

  const COMPONENT_ID = "dialog_add_to_cart";

  return (
    <div
      id={`${COMPONENT_ID}_special_order_confirmation`}
      data-testid={`${COMPONENT_ID}_special_order_confirmation`}
      key={`${COMPONENT_ID}_special_order_confirmation`}
      style={{
        minHeight: theme.spacing(44),
        paddingBottom: 80,
        overflow: "hidden"
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            className={classNames([
              typographyClasses.rhBaseH3,
              typographyClasses.uppercaseText
            ])}
          >
            {pageContent?.SPECIAL_ORDER}{" "}
            {keycloak?.authenticated && userType === "CONTRACT"
              ? pageContent?.SPO_CONFIRMATION?.CONTRACT_TERMS_OF_SALE
              : pageContent?.SPO_CONFIRMATION?.TERM_OF_SALE}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {SPOAddonVariables?.length && (
              <Grid item xs={12}>
                <Typography
                  component="p"
                  style={{ marginBottom: 0 }}
                  className={typographyClasses.reImagineBody1}
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html: pageContent?.Special_order_items ?? ""
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {SPOAddonVariables?.length &&
            SPOAddonVariables?.filter(spoItem => spoItem?.spo === true)?.map(
              (item, index) => (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: "32px", paddingBottom: "3px" }}
                  >
                    <Typography
                      className={typographyClasses.rhBaseH3}
                      dangerouslySetInnerHTML={{
                        __html: item?.displayName?.toLocaleUpperCase()
                      }}
                    ></Typography>
                  </Grid>

                  {!!item.pricing && (
                    <Grid item xs={12} style={{ marginBottom: 16 }}>
                      <RHRPriceDisplay
                        listPrice={item?.pricing?.listPrice!}
                        memberPrice={
                          item?.displayName
                            ?.toLocaleLowerCase()
                            .includes("gift card")
                            ? item?.pricing?.listPrice
                            : getPriceUserType(userType!, item?.pricing)!
                        }
                        topLabel={""} //pageContent?.CONFIGURES_PRICE
                        isSaleFilterEnabled={false}
                        onSale={item?.pricing?.onSale!}
                        skulowestMemberPrice={
                          getPriceUserType(userType!, item?.pricing)!
                        }
                        showSaleMessage={false}
                        userType={userType! || ""}
                        pageContent={pageContent}
                        variant={"small"}
                        centerAlignFlag={false}
                        isSkuLevel={true}
                        source={"spo"}
                        hideCss={true}
                      />
                    </Grid>
                  )}

                  {item?.fullSkuId && (
                    <Grid item container key={`item-fullSkuId`}>
                      <Grid item xs={3}>
                        <Typography
                          className={classNames(
                            typographyClasses.skuListColumn1,
                            typographyClasses.skuListSpo,
                            typographyClasses.uppercaseText
                          )}
                        >
                          {pageContent?.SPO_CONFIRMATION?.ITEM}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classNames(
                            typographyClasses.skuListColumn2,
                            typographyClasses.skuListSpo
                          )}
                        >
                          {item?.fullSkuId}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {item?.ProductAddonOptions &&
                    item?.ProductAddonOptions.map((option, index) => (
                      <Grid
                        item
                        container
                        key={`productOption-${option?.value}`}
                      >
                        <Grid item xs={3}>
                          <Typography
                            className={classNames(
                              typographyClasses.skuListColumn1,
                              typographyClasses.skuListSpo,
                              typographyClasses.uppercaseText
                            )}
                          >
                            {option?.name || option?.type}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classNames(
                              typographyClasses.skuListColumn2,
                              typographyClasses.skuListSpo
                            )}
                          >
                            {he.decode(option?.value)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  {item?.qty && (
                    <Grid item container key={`item-quantity`}>
                      <Grid item xs={3}>
                        <Typography
                          className={classNames(
                            typographyClasses.skuListColumn1,
                            typographyClasses.skuListSpo,
                            typographyClasses.uppercaseText
                          )}
                        >
                          {pageContent?.SPO_CONFIRMATION?.QUANTITY}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classNames(
                            typographyClasses.skuListColumn2,
                            typographyClasses.skuListSpo
                          )}
                        >
                          {item?.qty}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              )
            )}
        </Grid>

        <Grid style={{ marginTop: 18 }} item xs={12}>
          <Button
            id="specialOrderConfirmation_addToCart-btn"
            variant="contained"
            color="primary"
            onClick={spoConfirmOnClick}
            className={
              "!text-white !bg-black hover:!bg-[#404040] hover:!border-[#404040] h-[48px]"
            }
            fullWidth
          >
            {pageContent?.AGREE_AND_ADD_TO_CART}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

SpecialOrderConfirmation.defaultProps = {};

export default SpecialOrderConfirmation;
