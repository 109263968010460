import React, { FC, useEffect } from "react";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";
import SpecialOrderConfirmation from "./SpecialOrderConfirmation";
import Confirmed from "./Confirmed";
import RHSpinner from "@RHCommerceDev/component-rh-spinner";
import PanelAddToCartButton from "./PanelAddToCartButton";
import RHRSpecialOrderConfirmation from "./RHRSpecialOrderConfirmation";
import useState from "@RHCommerceDev/hooks/useState";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import ProductAddon from "@RHCommerceDev/component-product-addon";
import {
  addOnDataType,
  AddOnConfigModes
} from "@RHCommerceDev/component-product-addon/types";
import { useParams2 } from "@RHCommerceDev/hooks/useParams";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import AddToCartErrorMessage from "./AddToCartErrorMessage";
import { AddToCartDialogProps } from "./type";
import memoize from "utils/memoize";

export const AddToCartDialog: FC<AddToCartDialogProps> = ({
  qty,
  spo,
  preBillMessage,
  productDisplayName,
  options,
  confirmed,
  onConfirmed,
  loading,
  error,
  onClose,
  fullSkuId,
  salePriceLabel,
  pricing,
  productAddonsInfo,
  onAddOnConfigChange,
  handlePanelAddToCartClick,
  panelProdConfigurationData,
  productAddonDisplayOptions,
  panelProdData,
  incomingPanelProdConfigData,
  showPanelAddon,
  productLineItemOptions,
  panelQty,
  setPanelQty,
  productAddons,
  setAddedFromPanel,
  parentPageContent,
  ...rest
}) => {
  const env = useEnv();
  const FEATURE_PDP = useIsoCookies(["FEATURE_PDP"]).FEATURE_PDP;
  const isPDP = yn(env.FEATURE_PDP) || yn(FEATURE_PDP);

  const [disableATC, setDisableATC] = useState<boolean>(true);

  const { params } = useParams2<{ [key: string]: string }>(
    { productId: "", version: "" },
    { toLowerCase: true }
  );

  const isNewPDPLayOut: boolean =
    params.version === "v2" || memoryStorage.getItem("newPdpLayout");

  const addOnsExist = !!productAddonsInfo?.length;

  const shouldDisplayAddOns = productAddonDisplayOptions
    ? ["Panel", "Checkbox and Panel"].includes(productAddonDisplayOptions || "")
    : true;

  useEffect(() => {
    if (addOnsExist && !isNewPDPLayOut) {
      setDisableATC(
        (panelQty ?? 0) < 1 ||
          (panelProdConfigurationData?.selectedOptions?.length !==
            (panelProdData?.productLineItem?.availableOptions?.length ?? 0) &&
            !panelProdConfigurationData?.preBillMessage) ||
          (incomingPanelProdConfigData ?? false)
      );
    }
  }, [panelProdConfigurationData, incomingPanelProdConfigData]);

  useEffect(() => {
    // if it's the main special order product then no need to show  SwitchSpecialOrderConfirmation else if it's from related special order product then SwitchSpecialOrderConfirmation will be showed
    // Removed SPO panel for relatedproducts as well
    if (isNewPDPLayOut || yn(env.FEATURE_CART_SPO)) {
      onConfirmed?.();
    }
  }, []);

  const handleAddOnData = (
    addOnData: addOnDataType,
    addOnConfigModes: AddOnConfigModes
  ) => {
    // setPanelQty?.(spo ? qty : addOnData?.qty ?? qty);
    setPanelQty?.(addOnData?.qty ?? qty);
    onAddOnConfigChange?.(addOnData, addOnConfigModes);
  };

  const SPOAddon = productAddons?.filter(item => item?.spo)?.length;
  const SPOVariables = [
    {
      pricing: pricing,
      fullSkuId,
      displayName: productDisplayName,
      ProductAddonOptions: options,
      qty: panelQty,
      spo,
      preBillMessage
    },
    ...(productAddons?.filter(item => item?.spo) ?? [])
  ];

  if (loading) {
    return <RHSpinner />;
  }

  if (error) {
    return <AddToCartErrorMessage errorMessage={rest.errorMessage} />;
  }

  if (confirmed && addOnsExist && panelProdData && showPanelAddon) {
    return (
      <>
        <Confirmed
          qty={qty}
          onClose={onClose}
          isAddon={addOnsExist}
          parentPageContent={parentPageContent}
        />
        {shouldDisplayAddOns && (
          <>
            <ProductAddon
              loading={loading}
              data={panelProdData ?? {}}
              opts={panelProdData?.productLineItem?.availableOptions}
              imgURL={panelProdData?.productLineItem?.image?.imageUrl}
              handleAddonData={handleAddOnData}
              productLineItemOptions={productLineItemOptions}
              panelProdConfigurationData={panelProdConfigurationData}
            />
            <PanelAddToCartButton
              handleAddToCartClick={handlePanelAddToCartClick}
              isDisabled={disableATC}
            />
          </>
        )}
      </>
    );
  }

  if (confirmed) {
    return (
      <Confirmed
        qty={qty}
        onClose={onClose}
        isAddon={false}
        parentPageContent={parentPageContent}
      />
    );
  }

  const SwitchSpecialOrderConfirmation = isPDP
    ? RHRSpecialOrderConfirmation
    : SpecialOrderConfirmation;

  if ((spo && !confirmed) || (SPOAddon && !confirmed)) {
    return (
      <SwitchSpecialOrderConfirmation
        confirmationText={preBillMessage ?? ""}
        productDisplayName={productDisplayName ?? ""}
        options={options ?? []}
        fullSkuId={fullSkuId}
        qty={qty}
        salePriceLabel={salePriceLabel}
        pricing={pricing}
        onConfirmed={() => {
          onConfirmed?.();
        }}
        SPOAddonVariables={SPOVariables}
        setAddedFromPanel={setAddedFromPanel!}
      />
    );
  }

  return null;
};

AddToCartDialog.defaultProps = {};

export default memoize(AddToCartDialog);
