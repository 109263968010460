import { PropTypes } from "@RHCommerceDev/aem/ComponentWrapper";

export default {
  title: "Custom Window Dialog",
  type: "rh/components/content/custom-window-dialog",
  isContainer: false,
  config: {
    emptyLabel: "Custom Window Dialog",
    isEmpty: (_: PropTypes) => false
  }
};
