import React, { FC, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography
} from "@RHCommerceDev/utils/material-ui-core";
import ErrorIcon from "@RHCommerceDev/icon-error";
import {
  SOMETHING_WENT_WRONG,
  Please_try_again,
  TRY_AGAIN
} from "@RHCommerceDev/resources/error-dialog.json";

import RHDialogTitle from "@RHCommerceDev/component-rh-dialog-title";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { getSupportPhoneNumberByCountry } from "@RHCommerceDev/utils/getSupportPhoneNumberByCountry";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useIsoRedirect from "@RHCommerceDev/hooks-use-isoredirect";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import { RH_CUSTOMER_NUMBER } from "@RHCommerceDev/utils/constants";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2.2, 1, 7.2, 1)
    }
  })
);

export interface ErrorDialogProps {
  open: boolean;
  onClose: () => void;
  customTitle?: string;
  errorMessage?: string | string[];
  customSupportMessage?: string;
  disableButton?: boolean;
}

export const ErrorDialog: FC<ErrorDialogProps> = ({
  open,
  onClose,
  customTitle = null,
  errorMessage = null,
  customSupportMessage = null,
  disableButton = false
}) => {
  const {
    previousState: { country }
  } = useUserPreferences();

  const { pageContent } = useFetchModel("admin/error-dialog", true);

  const SUPPORT_CALL = useMemo(() => {
    return (pageContent?.Please_try_again || Please_try_again)?.replace(
      new RegExp(RH_CUSTOMER_NUMBER),
      getSupportPhoneNumberByCountry(country)
    );
  }, [country, pageContent?.Please_try_again]);

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleRefresh = () => {
    const req = getReqContext();
    let url = "";
    if (processEnvServer) {
      url = req.protocol + "://" + req.get("host") + req.originalUrl;
    } else {
      url = "";
    }
    useIsoRedirect(url, "windowReload"); // Forces the page to reload
  };

  return (
    <Dialog
      id="error-dialog"
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <RHDialogTitle onClose={handleClose} solidWhite />
      <DialogContent className={classes.content}>
        <Grid container justify="center" alignItems="center">
          <ErrorIcon style={{ fontSize: 36 }} />
          <Grid
            item
            xs={12}
            container
            justify="center"
            style={{ margin: "20px 0 5px 0" }}
          >
            <Typography
              variant="h3"
              align="center"
              style={{ maxWidth: "55ch" }}
            >
              {customTitle
                ? customTitle
                : pageContent?.SOMETHING_WENT_WRONG || SOMETHING_WENT_WRONG}
            </Typography>
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                {errorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} style={{ margin: "15px 0 20px 0" }}>
            <Typography align="center">
              {customSupportMessage ? customSupportMessage : SUPPORT_CALL}
            </Typography>
          </Grid>
          {!disableButton && (
            <Grid item xs={12} md={4}>
              <Button
                onClick={handleRefresh}
                variant="contained"
                color="primary"
                fullWidth
              >
                {pageContent?.TRY_AGAIN || TRY_AGAIN}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ErrorDialog.defaultProps = {};

export default ErrorDialog;
