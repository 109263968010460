import React, { FC } from "react";
import {
  Button,
  Grid,
  Typography,
  useTheme,
  FormControl
} from "@RHCommerceDev/utils/material-ui-core";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import classNames from "classnames";
import useButtonStyles from "@RHCommerceDev/hooks/useButtonStyles";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { usePageContent } from "customProviders/LocationProvider";
import { ConfirmedProps } from "./type";
import { useHistory } from "react-router";

export const Confirmed: FC<ConfirmedProps> = ({
  qty,
  onClose,
  isAddon = false,
  parentPageContent = {}
}) => {
  const { pageContent: defaultPageContent } = usePageContent();
  const pageContent = { ...parentPageContent, ...defaultPageContent };
  const theme = useTheme();
  const prefix = useLocalization();
  const env = useEnv();
  const history = useHistory();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody2", "rhBaseBody1"]
  });
  const buttonStyles = useButtonStyles({
    keys: ["primaryBlackBtn", "secondaryBtn"]
  });

  const COMPONENT_ID = "dialog_add_to_cart";

  return (
    <>
      <Grid
        id={`${COMPONENT_ID}_confirmed`}
        data-testid={`${COMPONENT_ID}_confirmed`}
        key={`${COMPONENT_ID}_confirmed`}
        item
        xs={12}
        style={{
          paddingBottom: theme.spacing(6.5),
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography
          component="p"
          style={{ textTransform: "uppercase" }}
          className={classNames([
            "text-center md:text-left",
            env?.FEATURE_PDP
              ? typographyStyles.rhBaseBody1
              : typographyStyles.rhBaseBody2
          ])}
        >
          {`${qty} ${qty > 1 ? pageContent?.ITEMS : pageContent?.ITEM} ${
            pageContent?.ADDED_TO_YOUR_CART
          }`}
        </Typography>
      </Grid>
      <Grid container spacing={isAddon ? 2 : 0}>
        <Grid item xs={isAddon ? 6 : 12}>
          <FormControl margin={"normal"} fullWidth>
            <Button
              id="ajax-proceed-to-cart"
              onClick={() =>
                history.push(`${prefix}/checkout/shopping_cart.jsp`)
              }
              className={classNames([buttonStyles.primaryBlackBtn])}
              autoFocus
            >
              {pageContent?.VIEW_CART}
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={isAddon ? 6 : 12}>
          <FormControl margin={"normal"} fullWidth>
            <Button
              id="ajax-continue-shopping"
              className={classNames([
                buttonStyles.secondaryBtn,
                "!text-[7px] md:!text-[9px] lg:!text-[11px]"
              ])}
              onClick={event => onClose?.(event, "escapeKeyDown")}
            >
              {pageContent?.KEEP_SHOPPING}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

Confirmed.defaultProps = {};

export default Confirmed;
