import React, { FC, useEffect, useMemo } from "react";
import {
  DialogContent,
  Typography,
  FormControl,
  Button,
  Grid
} from "@RHCommerceDev/utils/material-ui-core";
import { DialogProps } from "@material-ui/core/Dialog";
import RHTextField from "@RHCommerceDev/component-rh-text-field";
import RHSpinner from "@RHCommerceDev/component-rh-spinner";
import { ApolloError, useQuery, useMutation } from "@apollo/client";
import { queryBaseGalleries } from "@RHCommerceDev/graphql-client/queries/galleries";
import useState from "@RHCommerceDev/hooks/useState";
import { mutationRequestConsultation } from "@RHCommerceDev/graphql-client-queries-request-design-consultation";
import { queryScheduleAppointment } from "@RHCommerceDev/graphql-client/queries/content";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import RHLink from "@RHCommerceDev/component-rh-link";
import {
  BlurTextValidator,
  BlurSelectValidator
} from "@RHCommerceDev/component-input-validation";
import moment from "moment";
import { DESIGN_CONSULTATION_DATE_FORMAT } from "@RHCommerceDev/utils/constants";
import { ValidatorForm } from "react-material-ui-form-validator";
import useFormValidators from "@RHCommerceDev/hooks/useFormValidators";
import FormDialog from "@RHCommerceDev/layout-form-dialog";
import useStyles from "@RHCommerceDev/utils/buttonStyles";
import { useLocation } from "react-router";

interface RequestDesignConsultationInput {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone: Scalars["String"];
  preferredContact: Scalars["String"];
  description: Scalars["String"];
  leadSource: Scalars["String"];
  requestType?: Maybe<Scalars["String"]>;
  inquiryDate: Scalars["String"];
  galleryNumber: Scalars["String"];
  country: Scalars["String"];
  sessionConfirmation?: Maybe<Scalars["String"]>;
}

import { useFetchModel } from "hooks/useFetchModel";
import { getLocaleFromPath } from "utils/intlUtils";
import { phoneRegex } from "utils/regex";
import { useRhUserAtomValue } from "hooks/atoms";
import { useKeycloak } from "utils/Keycloak/KeyCloak";

export interface RequestConsultationDialogContentProps {
  onClose:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
  galleries: Gallery[];
  scheduleAppointment: {
    heading: {
      text: string;
    };
    title: {
      text: string;
    };
    members: {
      text: string;
    };
    placeholder: {
      text: string;
    };
    success: {
      text: string;
    };
  };
  onRequest: (input: RequestDesignConsultationInput) => Promise<string>;
  loading: boolean;
  error: ApolloError | undefined;
}

export const RequestConsultationDialogContent: FC<
  RequestConsultationDialogContentProps
> = ({
  galleries = [],
  scheduleAppointment = {},
  onRequest,
  loading,
  error
}) => {
  const rhUser = useRhUserAtomValue();
  const { keycloak } = useKeycloak();
  const [state, , dispatch] = useState({
    firstName: keycloak?.authenticated ? rhUser?.firstName : "",
    lastName: keycloak?.authenticated ? rhUser?.lastName : "",
    email: keycloak?.authenticated ? rhUser?.email : "",
    phone: "",
    galleryNumber: "",
    preferredContact: "NO PREFERENCE",
    description: "",
    requestType: "Interior Design",
    inquiryDate: moment(new Date()).format(DESIGN_CONSULTATION_DATE_FORMAT),
    country: "US",
    leadSource: "Web"
  });
  const classes = useStyles();
  const location = useLocation();
  const { locale } = getLocaleFromPath(location?.pathname) || {
    locale: "/us/en/"
  };
  const { pageContent } = useFetchModel(
    `${locale}/admin/gallery-details`,
    true
  );

  const { isSubmitButtonDisabled, isPhonePreferredContact } = useMemo(() => {
    const isPhonePreferredContact = state.preferredContact === "PHONE";
    const isSubmitButtonDisabled = Object.entries(state).some(entry =>
      isPhonePreferredContact || entry[0] !== "phone" ? !entry[1] : false
    );
    return {
      isPhonePreferredContact,
      isSubmitButtonDisabled
    };
  }, [state]);

  useEffect(() => {
    analyticsLoader(a =>
      a.emitAnalyticsEvent(
        // @ts-ignore
        document.querySelector("#spa-root > *")!,
        a.EVENTS.REQ_CONSULTATION_CLICK.INT_TYPE
      )
    );
    ValidatorForm.addValidationRule("isPhoneCustom", value => {
      if (value.match(phoneRegex)) {
        return true;
      }
      return false;
    });
  }, []);

  useEffect(() => {
    return () => {
      ValidatorForm.removeValidationRule("isPhoneCustom");
    };
  }, []);

  useFormValidators();

  const [statusCode, setStatusCode] = useState<string>("");
  const [isLocationValid, setLocationValid] = useState<boolean>(true);

  const handleBlurValidate = (event: React.FocusEvent<HTMLInputElement>) => {
    if (
      event.target.name === "gallery-select" &&
      event.target.value === "unchosen"
    ) {
      setLocationValid(false);
    } else {
      setLocationValid(true);
    }
  };

  if (statusCode === "200") {
    return (
      <DialogContent>
        <Typography
          style={{
            textTransform: "initial"
          }}
          dangerouslySetInnerHTML={{
            __html: scheduleAppointment.success?.text ?? ""
          }}
          paragraph
        />
      </DialogContent>
    );
  }

  return (
    <DialogContent style={{ overflow: "hidden" }}>
      <Typography
        variant="h1"
        dangerouslySetInnerHTML={{
          __html: scheduleAppointment.heading
            ? scheduleAppointment.heading.text
            : ""
        }}
        paragraph
      />
      <Typography
        dangerouslySetInnerHTML={{
          __html: scheduleAppointment.title?.text ?? ""
        }}
        paragraph
      />
      <RHLink href="/membership.jsp">
        <Typography
          style={{
            textTransform: "initial"
          }}
          dangerouslySetInnerHTML={{
            __html: scheduleAppointment.members?.text ?? ""
          }}
          paragraph
        />
      </RHLink>
      <ValidatorForm
        debounceTime={1000}
        instantValidate={false}
        onSubmit={onSubmit}
        data-testid="request-consultation-form"
      >
        <Grid container spacing={2}>
          {loading && (
            <Grid item container justify="center" alignItems="center">
              <RHSpinner
                data-testid="request-consultation-loading-spinner"
                style={{
                  zIndex: 1
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.FIRST_NAME}
              value={state.firstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("firstName")(event.target.value)
              }
              name="firstName"
              id="firstName"
              inputProps={{
                "data-testid": "first-name"
              }}
              validators={["required", "minStringLength:2"]}
              errorMessages={[
                pageContent?.FIRST_NAME_REQ,
                pageContent?.MUST_BE_ATLEAST_TWO_CHAR
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.LAST_NAME}
              value={state.lastName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("lastName")(event.target.value)
              }
              name="lastName"
              id="lastName"
              inputProps={{
                "data-testid": "last-name"
              }}
              validators={["required", "minStringLength:2"]}
              errorMessages={[
                pageContent?.LAST_NAME_REQ,
                pageContent?.MUST_BE_ATLEAST_TWO_CHAR
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.EMAIL}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("email")(event.target.value)
              }
              name="email"
              id="email"
              value={state.email}
              inputProps={{
                "data-testid": "email"
              }}
              validators={["required", "isEmail"]}
              errorMessages={[
                pageContent?.EMAIL_REQ,
                pageContent?.NOT_VALID_EMAIL
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.PHONE}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("phone")(event.target.value)
              }
              name="phone"
              id="phone"
              value={state.phone}
              inputProps={{
                "data-testid": "phone"
              }}
              validators={
                isPhonePreferredContact
                  ? ["required", "isPhoneCustom"]
                  : !!state.phone
                  ? ["isPhoneCustom"]
                  : []
              }
              errorMessages={
                isPhonePreferredContact
                  ? [pageContent?.PHONE_REQ, pageContent?.NOT_VALID_PHONE]
                  : !!state.phone
                  ? [pageContent?.NOT_VALID_PHONE]
                  : []
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RHTextField
              label={pageContent?.SELECT_NEAREST_LOCATION}
              value={
                state.galleryNumber
                  ? state.galleryNumber
                  : pageContent?.UNCHOSEN
              }
              style={{ marginTop: 0 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                dispatch("galleryNumber")(event.target.value);
              }}
              onBlur={handleBlurValidate}
              fullWidth
              required
              select
              id="gallery-select"
              name="gallery-select"
              error={!isLocationValid}
              inputProps={{
                "data-testid": "gallery-select"
              }}
              helperText={
                !isLocationValid ? pageContent?.NEAREST_LOCATION_REQ : ""
              }
            >
              <option key={"none"} value={"unchosen"}>
                {pageContent?.SELECT_LOCATION}
              </option>
              {galleries.map(gallery => {
                if (
                  gallery.serviceOfferings &&
                  gallery.serviceOfferings.offersInteriorDesign
                ) {
                  return (
                    <option
                      key={gallery.docId}
                      value={gallery.number?.toString()}
                      data-country={gallery.address?.country}
                    >
                      {gallery.address.state}, {gallery.address.city} -{" "}
                      {gallery.name}
                    </option>
                  );
                }
                return null;
              })}
            </RHTextField>
          </Grid>
          <Grid item container xs={12} sm={6}>
            <FormControl component="fieldset" fullWidth>
              <BlurSelectValidator
                id="preferred-contact"
                name="preferred-contact"
                label={pageContent?.PREFERRED_CONTACT_METHOD}
                fullWidth
                select
                variant="outlined"
                value={state.preferredContact}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch("preferredContact")(event.target.value)
                }
                inputProps={{
                  "data-testid": "preferred-contact"
                }}
                validators={["required"]}
                errorMessages={[pageContent?.PREFERRED_CONTACT_METHOD_REQ]}
              >
                <option key={"NO_PREFERENCE"} value={"NO PREFERENCE"}>
                  {pageContent?.NO_PREFERENCE}
                </option>
                <option key={"EMAIL"} value={"EMAIL"}>
                  {pageContent?.EMAIL}
                </option>
                <option key={"TEXT"} value={"TEXT"}>
                  {pageContent?.TEXT}
                </option>
                <option key={"PHONE"} value={"PHONE"}>
                  {pageContent?.PHONE}
                </option>
                <option key={"VIDEO"} value={"VIDEO"}>
                  {pageContent?.VIDEO_CONFERENCE_CALL}
                </option>
              </BlurSelectValidator>
            </FormControl>
          </Grid>
          <Grid item container xs={12}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.WHAT_CAN_WE_HELP_YOU_WITH}
              placeholder={scheduleAppointment.placeholder?.text ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("description")(event.target.value)
              }
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                "data-testid": "description"
              }}
              multiline
              rows="5"
              name="description"
              id="description"
              value={state.description}
              validators={["required", "minStringLength:2"]}
              errorMessages={[
                pageContent?.DESCRIPTION_IS_REQ,
                pageContent?.MUST_BE_ATLEAST_TWO_CHAR
              ]}
            />
          </Grid>
          <Grid item container xs={12} sm={6}>
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || isSubmitButtonDisabled}
                fullWidth
                className={classes.containedPrimary}
              >
                {pageContent?.REQUEST_A_CONSULTATION}
              </Button>
            </FormControl>
          </Grid>
          {error && (
            <Grid item container xs={12}>
              <Typography color="error" align="center" variant="body2">
                {pageContent?.SOMETHING_WRONG}
              </Typography>
            </Grid>
          )}
        </Grid>
      </ValidatorForm>
    </DialogContent>
  );

  async function onSubmit(event: React.FormEvent) {
    const { ...input } = state;
    const gallery = (
      document.getElementById("gallery-select") as HTMLSelectElement
    )?.selectedOptions?.[0];

    try {
      const statusCode = await onRequest({
        ...input,
        country: gallery?.getAttribute("data-country") || input?.country
      });
      setStatusCode(statusCode);
    } catch (e) {}

    analyticsLoader(a =>
      a.emitAnalyticsEvent(
        // @ts-ignore
        document.querySelector("#spa-root > *")!,
        a.EVENTS.REQ_CONSULTATION.INT_TYPE,
        {
          gallery: {
            name: gallery?.innerText,
            number: gallery?.value
          }
        }
      )
    );
  }
};

RequestConsultationDialogContent.defaultProps = {};

export default ({ ...rest }: Omit<DialogProps, "children">) => {
  const { data: { galleries } = {} as Query } = useQuery<Query>(
    queryBaseGalleries,
    {
      skip: !rest.open
    }
  );

  const { data: scheduleAppointment } = useQuery(queryScheduleAppointment, {
    skip: !rest.open
  });

  const [requestConsultation, { loading, error }] = useMutation<
    Pick<Mutation, "requestConsultation">
  >(mutationRequestConsultation, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onError: error => {}
  });
  return (
    <FormDialog {...rest} onClose={rest.onClose}>
      <RequestConsultationDialogContent
        galleries={galleries}
        scheduleAppointment={scheduleAppointment}
        onClose={rest.onClose}
        onRequest={async input => {
          const { data } = await requestConsultation({
            variables: {
              ...input
            }
          });
          if (data) {
            return `${data?.requestConsultation?.statusCode}`;
          }
          return Promise.reject();
        }}
        loading={loading}
        error={error}
      />
    </FormDialog>
  );
};
