import React, { FC } from "react";
import { Button } from "@RHCommerceDev/utils/material-ui-core";
import { usePageContent } from "customProviders/LocationProvider";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import { useParams2 } from "@RHCommerceDev/hooks/useParams";
import { PanelAddToCartButtonProps } from "./type";
import classNames from "classnames";

export const PanelAddToCartButton: FC<PanelAddToCartButtonProps> = ({
  handleAddToCartClick,
  isDisabled
}) => {
  const { pageContent } = usePageContent();
  const { params } = useParams2<{ [key: string]: string }>(
    { productId: "", version: "" },
    { toLowerCase: true }
  );

  const isNewPDPLayOut: boolean =
    params.version === "v2" || memoryStorage.getItem("newPdpLayout");

  const COMPONENT_ID = "panel_add_to_cart_button";

  return (
    <div
      style={{
        paddingBottom: 80
      }}
    >
      <Button
        id={`${COMPONENT_ID}_addToCart`}
        data-testid={`${COMPONENT_ID}_addToCart`}
        key={`${COMPONENT_ID}_addToCart`}
        variant="contained"
        color="primary"
        onClick={() => {
          handleAddToCartClick?.();
        }}
        className={classNames([
          "text-white",
          {
            ["bg-[#DDDDDD]"]: isDisabled,
            ["bg-black hover:!bg-gray-1 hover:!border-gray-1"]: !isDisabled
          }
        ])}
        disabled={isDisabled}
        fullWidth
      >
        {isNewPDPLayOut
          ? pageContent?.ADD_TO_CART
          : pageContent?.AGREE_AND_ADD_TO_CART}
      </Button>
    </div>
  );
};

PanelAddToCartButton.defaultProps = {};

export default PanelAddToCartButton;
