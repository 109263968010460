import React, { useEffect, useCallback, useMemo } from "react";
import { DialogContent, useTheme } from "@RHCommerceDev/utils/material-ui-core";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";
import AddToCartErrorMessage from "./AddToCartErrorMessage";
import { useMutation } from "@apollo/client";
import RHDialogTitle from "@RHCommerceDev/component-rh-dialog-title";
import useState from "@RHCommerceDev/hooks/useState";
import { addLineItemsToCart } from "@RHCommerceDev/graphql-client-cart-broker/queries/add-line-items-to-cart";
import { queryGetCart } from "@RHCommerceDev/graphql-client-cart-broker/queries/get-cart";
import EventEmitter from "@RHCommerceDev/utils-event-emitter";
import isEmpty from "lodash.isempty";
import { ApolloError } from "@apollo/client";
import Drawer from "@RHCommerceDev/component-drawer";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";
import useSite from "@RHCommerceDev/hooks/useSite";
import { addOnDataType } from "@RHCommerceDev/component-product-addon/types";
import { usePageContent } from "customProviders/LocationProvider";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import { useParams2 } from "@RHCommerceDev/hooks/useParams";
import {
  useUserSessionAtomValue,
  useUserSessionSetAtom
} from "@RHCommerceDev/hooks/atoms";
import { useGetCartProjectLazyQuery } from "@RHCommerceDev/hooks/queries";
import { useKeycloak } from "@RHCommerceDev/utils/Keycloak/KeyCloak";
import productMapper from "@RHCommerceDev/utils/productMapper";
import AddToCartDialog from "./AddToCartDialog";
import { AddToCartDialogProps } from "./type";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";

export default ({
  data,
  monogramOrder,
  productId,
  onAddOnConfigChange,
  mainProductAvailableOptions,
  mainProductAvailableSwatch,
  productAddonDisplayOptions,
  productLineItemOptions,
  productAddons,
  productAddonsInfo,
  panelProdConfigurationData,
  panelAddOnsData,
  panelProdData,
  panelProds,
  incomingPanelProdConfigData,
  fullSkuId,
  atgSkuId,
  qty,
  spo,
  preBillMessage,
  productDisplayName,
  multiSkuComponentsDataV2,
  onCompleted,
  giftCardTo,
  giftCardFrom,
  pricing,
  customInfo,
  confirmed,
  setConfirmed,
  addToCartItemDetails,
  onPostalCode,
  swatchAddToCartItemDetails,
  mainProductChoosenOptions,
  parentPageContent = {},
  ...rest
}: AddToCartDialogProps) => {
  const env = useEnv();
  const enabled = yn(env.FEATURE_PDP_CART_BROKER);
  const addOnsExist = !!productAddonsInfo?.length;
  const { keycloak } = useKeycloak();
  const { currentCartId: cartId, rhUser } = useUserSessionAtomValue();
  const [getCartProjection] = useGetCartProjectLazyQuery();
  const brand = useSite();
  const { pageContent: defaultPageContent } = usePageContent();
  const pageContent = { ...parentPageContent, ...defaultPageContent };
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  let spoTerms = preBillMessage;
  if (keycloak?.authenticated && rhUser?.userType === "CONTRACT") {
    spoTerms = pageContent?.spoTerms;
  }

  const [spoAgreed, setSpoAgreed] = useState(false);
  const [showPanelAddon, setShowPanelAddon] = useState(false);
  const [addedFromPanel, setAddedFromPanel] = useState<boolean>(false);
  const [isEffectCalled, setIsEffectCalled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cartIdForPanelAddon, setCartIdForPanelAddon] = useState("");
  const cookies = useIsoCookies(["search_access", "navigating_from_search"]);
  const theme = useTheme();
  const { app } = useAppData();
  const pc = useIsoCookies(["pc"], true)?.pc;
  const {
    previousState: { country }
  } = useUserPreferences();
  const userCountry = useMemo(
    () => getCountryFromUrl() || country || "US",
    [country]
  );
  const setUserSession = useUserSessionSetAtom();

  const { params: queryParams } = useParams2<{ [key: string]: string }>(
    { version: "" },
    { toLowerCase: true }
  );

  const isNewPDPLayout =
    queryParams?.version === "v2" || memoryStorage.getItem("newPdpLayout");

  const multiSkuComponents = [];

  const fusionData = !processEnvServer && localStorage.getItem("fusion_data");
  const searchObj =
    !processEnvServer && localStorage.getItem("analytics-search");
  const access =
    cookies["search_access"] ||
    (!processEnvServer && localStorage.getItem("analytics-access"));
  const analyticsPrevUrl =
    !processEnvServer && localStorage.getItem("analytics-previous-url");
  const isPrevSearchPage =
    analyticsPrevUrl &&
    JSON.parse(analyticsPrevUrl)?.prevURL?.includes("results");
  const isFromSearch =
    cookies["navigating_from_search"] ||
    (!processEnvServer &&
      localStorage.getItem("analytics-navigatingFromSearch"));

  /***
   * HACK gift message is not part of the UI yet but the BE
   * requires it so just loading the query with empty string
   * until field is added to UI
   */

  const getFusionId = useMemo(() => {
    let fusionId = "";
    if (fusionData && searchObj && (isFromSearch || isPrevSearchPage)) {
      const tempFusionData = JSON.parse(fusionData);
      const tempSearchObj = JSON.parse(searchObj);
      const searchTerm = tempSearchObj.term;
      const searchAccess = access === "direct" ? "search" : access;
      if (tempFusionData.fusion_id) {
        fusionId = `${tempFusionData.fusion_id}~${searchTerm}~${searchAccess}`;
      }
    }

    return fusionId;
  }, [fusionData, searchObj, isFromSearch, access, isPrevSearchPage]);

  const [panelLineItems, setPanelLineItems] = useState<Array<LineItemInput>>(
    []
  );

  const [panelQty, setPanelQty] = useState<number>(qty);

  const panelVariables = useMemo(() => {
    return {
      email: rhUser?.email || "",
      cartId: cartId || cartIdForPanelAddon,
      lineItemsInput: {
        items: panelLineItems
      }
    };
  }, [cartId, rhUser?.email, panelLineItems, cartIdForPanelAddon]);

  useEffect(() => {
    if (addOnsExist) {
      setPanelLineItems([
        productMapper(
          {
            productId: panelProdConfigurationData?.productId,
            sku: panelProdConfigurationData?.fullSkuId,
            fusionId: getFusionId,
            quantity: panelQty,
            brand: brand,
            giftTo: giftCardTo ?? "",
            giftFrom: giftCardFrom ?? "",
            giftMessage: "",
            spoTermsAccepted: panelProdConfigurationData?.spo
              ? spoAgreed
              : false,
            spoTerms: spoAgreed ? spoTerms : null
          },
          undefined,
          customInfo
        )
      ]);
    }
  }, [
    productAddons,
    brand,
    customInfo,
    getFusionId,
    giftCardFrom,
    giftCardTo,
    monogramOrder,
    qty,
    panelQty,
    panelProdConfigurationData,
    spoAgreed,
    spoTerms
  ]);

  const multiskuBundleComponents = useMemo(
    () =>
      multiSkuComponents?.length
        ? multiSkuComponents.map(component => {
            return productMapper(
              {
                productId: component?.productId,
                sku: component?.fullSkuId,
                multiSkuId: fullSkuId, // fullSkuId will contain a multiSkuId m1010101
                fusionId: getFusionId,
                quantity: qty * component?.quantity!,
                brand: brand,
                giftTo: giftCardTo ?? "",
                giftFrom: giftCardFrom ?? "",
                giftMessage: "",
                spoTermsAccepted: multiSkuComponentsDataV2?.isSPO
                  ? spoAgreed
                  : false,
                spoTerms: spoAgreed ? spoTerms : null
              },
              monogramOrder,
              customInfo
            );
          })
        : [],
    [multiSkuComponents, qty, spoAgreed]
  );

  const addOnComponents = useMemo(
    () =>
      productAddons?.map(item => ({
        productId: item?.productId,
        sku: item?.fullSkuId,
        fusionId: getFusionId,
        quantity: item?.qty ?? 1,
        brand: brand,
        giftTo: giftCardTo ?? "",
        giftFrom: giftCardFrom ?? "",
        giftMessage: "",
        spoTermsAccepted: item?.spo ? spoAgreed : false,
        spoTerms: spoAgreed ? item?.preBillMessage : null
      })) ?? [],
    [productAddons, qty, spoAgreed]
  );
  const addOnCartData = [...multiskuBundleComponents, ...addOnComponents];

  const swatchItemDetails = useMemo(
    () =>
      swatchAddToCartItemDetails?.map(item =>
        productMapper(
          {
            productId: item?.swatchProductId ?? "",
            sku: item?.skuIds ?? "",
            fusionId: getFusionId,
            quantity: qty ?? 1,
            brand: brand,
            giftTo: giftCardTo ?? "",
            giftFrom: giftCardFrom ?? "",
            giftMessage: "",
            spoTermsAccepted: spoAgreed,
            spoTerms: spoAgreed ? spoTerms : null
          },
          monogramOrder,
          customInfo
        )
      ) ?? [],
    [swatchAddToCartItemDetails, qty]
  );

  const variables: MutationAddLineItemsToCartArgs = {
    email: rhUser?.email || "",
    cartId: cartId || "",
    lineItemsInput: {
      items:
        addOnsExist && productAddons?.length
          ? multiSkuComponents?.length
            ? addOnCartData
            : [
                ...addOnComponents,
                ...(!isEmpty(swatchItemDetails)
                  ? swatchItemDetails
                  : [
                      productMapper(
                        {
                          productId: productId,
                          sku: fullSkuId,
                          fusionId: getFusionId,
                          quantity: qty,
                          brand: brand,
                          giftTo: giftCardTo ?? "",
                          giftFrom: giftCardFrom ?? "",
                          giftMessage: "",
                          spoTermsAccepted: spo ? spoAgreed : false,
                          spoTerms: spoAgreed ? spoTerms : null
                        },
                        monogramOrder,
                        customInfo
                      )
                    ])
              ]
          : multiSkuComponents?.length
          ? multiskuBundleComponents
          : [
              ...(!isEmpty(swatchItemDetails)
                ? swatchItemDetails
                : [
                    productMapper(
                      {
                        productId: productId,
                        sku: fullSkuId,
                        fusionId: getFusionId,
                        quantity: qty,
                        brand: brand,
                        giftTo: giftCardTo ?? "",
                        giftFrom: giftCardFrom ?? "",
                        giftMessage: "",
                        spoTermsAccepted: spo ? spoAgreed : false,
                        spoTerms: spoAgreed ? spoTerms : null
                      },
                      monogramOrder,
                      customInfo
                    )
                  ])
            ]
    },
    ...{
      createCartInfo: {
        guest: {
          email: rhUser?.email || "",
          userId: rhUser?.id,
          userType: rhUser?.userType
        },
        postalCode: pc,
        country: userCountry
      }
    }
  };

  const numberOfItemsAddedToCartFromPDP = useMemo(() => {
    if (addOnsExist) {
      const panelLineItem = variables?.lineItemsInput?.items
        ?.map(lineItem => {
          const addOnData = productAddonsInfo?.find(
            productAddOn => productAddOn?.id === lineItem?.productId
          );
          return addOnData
            ? {
                name: addOnData?.displayName,
                sku: lineItem?.sku,
                id: addOnData?.id,
                quantity: lineItem?.quantity,
                inStockProductCard: false,
                price: addOnData?.priceRangeDisplay,
                color: addOnData?.productLineItem?.availableOptions
                  ?.find(opt => opt.type === "Color")
                  ?.options?.find(opt => opt?.status === "selected")?.value,
                atc_method: "add-on"
              }
            : null;
        })
        .filter(item => item !== null);
      if (!processEnvServer && !addedFromPanel) {
        memoryStorage.setItem("panelLineItem", {
          items: panelLineItem,
          addedFromPanel: false
        });
      }
    }
    return variables?.lineItemsInput?.items.reduce(
      (totalQty, item) => totalQty + item?.quantity,
      0
    );
  }, [variables]);

  const getProductNamesByFullSkuId = useCallback(
    (skuIds: string[]) => {
      const productNames: Maybe<string>[] = [];
      skuIds?.forEach(skuId => {
        if (skuId === fullSkuId) {
          productNames.push(productDisplayName || "");
        } else {
          let productAddOnData: Maybe<addOnDataType & ProductAddonsInfo>;
          if (addedFromPanel) {
            if (isNewPDPLayout) {
              const addedPanelProdId = panelAddOnsData?.find(
                addOn => addOn?.fullSkuId === skuId
              )?.productId;
              productAddOnData = productAddonsInfo?.find(
                addOn => addOn?.id === addedPanelProdId
              );
            } else {
              const addedPanelProdId = panelProdConfigurationData?.productId;
              productAddOnData = productAddonsInfo?.find(
                addOn => addOn?.id === addedPanelProdId
              );
            }
          } else {
            productAddOnData = productAddons?.find(
              addOn => addOn?.fullSkuId === skuId
            );
          }
          !!productAddOnData &&
            productNames.push(productAddOnData?.displayName);
        }
      });

      return productNames?.join(", ");
    },
    [panelAddOnsData, productAddons, panelProdConfigurationData, addedFromPanel]
  );

  const [addItemsToCart, { loading, error }] = useMutation<
    Pick<Mutation, "addLineItemsToCart">,
    MutationAddLineItemsToCartArgs
  >(addLineItemsToCart, {
    update(cache, result) {
      cache.writeQuery({
        query: queryGetCart,
        variables: { email: rhUser?.email, cartId },
        data: { getCart: result?.data?.addLineItemsToCart }
      });
    },
    onCompleted: async data => {
      setConfirmed?.(true);
      setTimeout(() => {
        onCompleted?.(data?.addLineItemsToCart);
        getCartProjection();
        setCartIdForPanelAddon(data?.addLineItemsToCart?.id || "");
        if (isEmpty(cartId) && data?.addLineItemsToCart?.id) {
          setUserSession(prev => ({
            ...prev,
            currentCartId: data?.addLineItemsToCart?.id
          }));
        }
        EventEmitter.dispatch("header-refetch", {});
      }, 0);
    },
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onError: (responseError: ApolloError) => {
      try {
        const networkError = JSON.parse(
          JSON.stringify(responseError?.networkError || {}, null, 2)
        )?.result?.errors?.[0];
        const { errorCode, message, errors } = JSON.parse(
          networkError?.message.substring(6)
        );
        let productNames = "";
        if (errors?.length) {
          productNames =
            getProductNamesByFullSkuId(errors?.map(error => error?.sku)) || "";
        }
        if (errorCode === "INVALID_ITEM_MAX_QUANTITY") {
          return setErrorMessage(message);
        } else if (
          errorCode === "INSUFFICIENT_INVENTORY" ||
          errorCode === "ITEM_QUANTITY_LIMIT_REACHED"
        ) {
          return setErrorMessage(
            `Insufficient inventory for ${productNames || productDisplayName}`
          );
        } else if (errorCode === "MULTIPART_ERROR") {
          return setErrorMessage(
            `Insufficient inventory for ${productNames || productDisplayName}`
          );
        } else if (errorCode === "GIFT_CARD_AMOUNT_EXCEEDS_MAX_ALLOWED") {
          return setErrorMessage(
            `${productDisplayName} EXCEEDS THE MAXIMUM GIFT CART LIMIT OF $10,000.`
          );
        }
        return <AddToCartErrorMessage />;
      } catch (error) {
        console.error(error);
        return <AddToCartErrorMessage />;
      }
    },
    variables
  });

  const numberOfItemsAddedToCartFromPanel = useMemo(() => {
    const panelLineItem = panelVariables?.lineItemsInput?.items
      ?.map(lineItem => {
        const addOnData = productAddonsInfo?.find(
          productAddOn => productAddOn?.id === lineItem?.productId
        );
        return addOnData
          ? {
              name: addOnData?.displayName,
              sku: lineItem?.sku,
              id: addOnData?.id,
              quantity: lineItem?.quantity,
              inStockProductCard: false,
              price: addOnData?.priceRangeDisplay,
              color: addOnData?.productLineItem?.availableOptions
                ?.find(opt => opt.type === "Color")
                ?.options?.find(opt => opt?.status === "selected")?.value,
              atc_method: "add-on-flyout"
            }
          : null;
      })
      .filter(item => item !== null);
    if (!processEnvServer && addedFromPanel) {
      memoryStorage.removeItem("panelLineItem");
      memoryStorage.setItem("panelLineItem", {
        items: panelLineItem,
        addedFromPanel: true
      });
    }
    return panelVariables?.lineItemsInput?.items.reduce(
      (totalQty, item) => totalQty + item?.quantity,
      0
    );
  }, [panelVariables]);

  const [addPanelItemsToCart, { loading: panelLoading, error: panelError }] =
    useMutation<
      Pick<Mutation, "addLineItemsToCart">,
      MutationAddLineItemsToCartArgs
    >(addLineItemsToCart, {
      onCompleted: async data => {
        setConfirmed?.(true);
        onCompleted?.(data?.addLineItemsToCart);
        await getCartProjection();
        EventEmitter.dispatch("header-refetch", {});
      },
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onError: (responseError: ApolloError) => {
        const networkError = JSON.parse(
          JSON.stringify(responseError?.networkError || {}, null, 2)
        )?.result?.errors?.[0];
        const {
          errorCode,
          message,
          errors,
          sku: errorSku
        } = JSON.parse(networkError?.message.substring(6));
        let productNames = getProductNamesByFullSkuId([errorSku]) || "";
        if (errors?.length) {
          productNames =
            getProductNamesByFullSkuId(errors?.map(error => error?.sku)) || "";
        }
        try {
          if (errorCode === "INVALID_ITEM_MAX_QUANTITY") {
            return setErrorMessage(message);
          } else if (
            errorCode === "INSUFFICIENT_INVENTORY" ||
            errorCode === "ITEM_QUANTITY_LIMIT_REACHED"
          ) {
            return setErrorMessage(
              `Insufficient inventory for ${productNames || productDisplayName}`
            );
          } else if (errorCode === "MULTIPART_ERROR") {
            return setErrorMessage(
              `Insufficient inventory for ${productNames || productDisplayName}`
            );
          } else if (errorCode === "GIFT_CARD_AMOUNT_EXCEEDS_MAX_ALLOWED") {
            return setErrorMessage(
              `${productDisplayName} EXCEEDS THE MAXIMUM GIFT CART LIMIT OF $10,000.`
            );
          }
          return <AddToCartErrorMessage />;
        } catch (error) {
          return <AddToCartErrorMessage />;
        }
      },
      variables: panelVariables
    });

  useEffect(() => {
    if (rest?.open) {
      let isAddonSpo: addOnDataType[] | undefined = [];
      if (!spoAgreed) {
        isAddonSpo = productAddons?.filter(item => item?.spo) || [];
        isAddonSpo && setSpoAgreed(false);
      }
      if (
        (enabled && !spo && isAddonSpo?.length === 0) ||
        (enabled && spo && spoAgreed)
      ) {
        if (!isEffectCalled) {
          setIsEffectCalled(true);
          addItemsToCart({
            update(cache, result) {
              cache.writeQuery({
                query: queryGetCart,
                variables: { email: rhUser?.email, cartId },
                data: { getCart: result?.data?.addLineItemsToCart }
              });
            }
          });
        }
      }
    }
  }, [
    isEffectCalled,
    rhUser?.email,
    cartId,
    addItemsToCart,
    enabled,
    spoAgreed,
    rest?.open,
    spo
  ]);

  const handleSpoAgreed = useCallback(() => {
    setSpoAgreed(true);
  }, []);

  useEffect(() => {
    if (addOnsExist) {
      setShowPanelAddon(true);
      // if (spo) setAddedFromPanel(true);
    }
  }, [productAddons, panelProdConfigurationData]);

  const qtyAddedToCart = addedFromPanel
    ? numberOfItemsAddedToCartFromPanel
    : numberOfItemsAddedToCartFromPDP;

  useEffect(() => {
    if (isNewPDPLayout) {
      setPanelLineItems(
        panelAddOnsData?.map(addOn => ({
          productId: addOn?.productId,
          sku: addOn?.fullSkuId,
          fusionId: getFusionId,
          quantity: 1,
          brand: brand,
          giftTo: giftCardTo ?? "",
          giftFrom: giftCardFrom ?? "",
          giftMessage: "",
          spoTermsAccepted: addOn?.spo ? spoAgreed : false,
          spoTerms: spoAgreed ? spoTerms : null,
          // ...(monogramOrder?.fontCode ||
          // monogramOrder?.fontColorCode ||
          // monogramOrder?.borderCode
          //   ? { monogram: cleanMonogramOrder(monogramOrder) }
          //   : null),
          ...(!isEmpty(customInfo) ? { customInfo } : null)
        })) ?? []
      );
    }
  }, [panelAddOnsData]);

  const handlePanelAddToCartClick = useCallback(() => {
    setAddedFromPanel(true);
    setShowPanelAddon(false);
    if (!isNewPDPLayout) {
      setPanelLineItems([
        {
          productId: panelProdConfigurationData?.productId,
          sku: panelProdConfigurationData?.fullSkuId,
          fusionId: getFusionId,
          quantity: panelQty,
          brand: brand,
          giftTo: giftCardTo ?? "",
          giftFrom: giftCardFrom ?? "",
          giftMessage: "",
          spoTermsAccepted: panelProdConfigurationData?.spo ? spoAgreed : false,
          spoTerms: spoAgreed ? spoTerms : null,
          // ...(monogramOrder?.fontCode ||
          // monogramOrder?.fontColorCode ||
          // monogramOrder?.borderCode
          //   ? { monogram: cleanMonogramOrder(monogramOrder) }
          //   : null),
          ...(!isEmpty(customInfo) ? { customInfo } : null)
        }
      ]);
    }
    addPanelItemsToCart({
      update(cache, result) {
        cache.writeQuery({
          query: queryGetCart,
          variables: { email: rhUser?.email, cartId },
          data: { getCart: result?.data?.addLineItemsToCart }
        });
      }
    });
  }, [
    productAddons,
    panelProdConfigurationData,
    getFusionId,
    panelQty,
    brand,
    giftCardTo,
    giftCardFrom,
    spoAgreed,
    spoTerms,
    monogramOrder,
    customInfo,
    addPanelItemsToCart,
    rhUser?.email,
    cartId
  ]);
  const SPOAddon = productAddons?.filter(item => item?.spo)?.length;

  return (
    <DrawerComponent
      id={rest?.id || "dialog-add-to-cart_drawer"}
      open={true}
      anchor={"right"}
      role="dialog"
      tabIndex={0}
      stopBackgroundScrolling={true}
      aria-label={
        (spo && !confirmed) || (SPOAddon && !confirmed)
          ? "Special Order"
          : `${qty} ${qty > 1 ? pageContent?.ITEMS : pageContent?.ITEM} ${
              pageContent?.ADDED_TO_YOUR_CART
            }`
      }
      {...rest}
      BackdropProps={{
        style: isNewPDPLayout
          ? {
              backgroundColor: "var(--ReimagineWarm-RH-Black, #000)",
              opacity: 0.15
            }
          : {},
        invisible: false
      }}
      PaperProps={{
        className: env?.FEATURE_PDP
          ? "w-[80%] sm:w-1/2 md:w-1/3"
          : "w-full sm:w-[69.4%] md:w-[53.73%] lg:w-[44.42%] xl:w-1/3",
        ...rest.PaperProps
      }}
    >
      <RHDialogTitle
        id={"dialog-add-to-cart_title"}
        style={{
          backgroundColor: theme.palette.background.default
        }}
        solidWhite
        onClose={rest.onClose}
        titlePaddingClassNames="py-6"
      />
      <DialogContent
        className={
          env?.FEATURE_PDP
            ? "!overflow-auto !py-10 !px-8 md:!px-10 xl:!py-20 xl:!px-20"
            : !showPanelAddon
            ? "overflow-auto py-0 px-4 sm:px-[60px] sm:py-0"
            : "overflow-auto p-4 sm:px-[60px] sm:py-0"
        }
      >
        <AddToCartDialog
          productAddonDisplayOptions={productAddonDisplayOptions}
          monogramOrder={monogramOrder}
          productId={productId}
          productAddons={productAddons}
          productAddonsInfo={productAddonsInfo}
          panelProdConfigurationData={panelProdConfigurationData}
          panelProdData={panelProdData}
          panelProds={panelProds}
          panelAddOnsData={panelAddOnsData}
          onAddOnConfigChange={onAddOnConfigChange}
          mainProductAvailableOptions={mainProductAvailableOptions}
          mainProductAvailableSwatch={mainProductAvailableSwatch}
          mainProductChoosenOptions={mainProductChoosenOptions}
          productLineItemOptions={productLineItemOptions}
          handlePanelAddToCartClick={handlePanelAddToCartClick}
          fullSkuId={fullSkuId}
          atgSkuId={atgSkuId}
          qty={qtyAddedToCart}
          preBillMessage={spoTerms}
          productDisplayName={productDisplayName}
          onCompleted={onCompleted}
          options={rest.options}
          pricing={pricing}
          loading={loading || panelLoading}
          error={!!error || !!panelError}
          onClose={rest.onClose}
          confirmed={confirmed}
          onConfirmed={handleSpoAgreed}
          showPanelAddon={showPanelAddon}
          errorMessage={errorMessage}
          salePriceLabel={rest?.salePriceLabel}
          setConfirmed={setConfirmed}
          spo={spo}
          multiSkuComponents={multiSkuComponents}
          setAddedFromPanel={setAddedFromPanel}
          // lineItemOptionSelectionForPanelAddOns={
          //   rest?.lineItemOptionSelectionForPanelAddOns
          // }
          // refactoredAddOnData={refactoredAddOnData}
          // SPOAddonVariables={refactoredSPOAddonData()}
          productAddedFromPanel={addedFromPanel}
          panelQty={panelQty}
          setPanelQty={setPanelQty}
          addToCartItemDetails={addToCartItemDetails}
          incomingPanelProdConfigData={incomingPanelProdConfigData}
          onPostalCode={onPostalCode}
          parentPageContent={parentPageContent}
        />
      </DialogContent>
    </DrawerComponent>
  );
};
