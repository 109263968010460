import React, { FC } from "react";
import {
  Button,
  Typography,
  Grid,
  Divider
} from "@RHCommerceDev/utils/material-ui-core";

import { useTheme } from "@material-ui/core";
import classNames from "classnames";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import he from "he";
import { usePageContent } from "customProviders/LocationProvider";
import { RHRSpecialOrderConfirmationProps } from "./type";

// Breakpoints by the numbers
export const BREAKPOINT_XS = 0;
export const BREAKPOINT_SM = 768;
export const BREAKPOINT_MD = 992;
export const BREAKPOINT_LG = 1200;
export const BREAKPOINT_XL = 1600;

export const RHRSpecialOrderConfirmation: FC<
  RHRSpecialOrderConfirmationProps
> = ({
  confirmationText,
  onConfirmed,
  productDisplayName,
  options,
  fullSkuId,
  qty,
  salePriceLabel = "Sale",
  pricing,
  SPOAddonVariables
}) => {
  const theme = useTheme();
  const typographyClasses = useTypographyStyles({
    keys: [
      "skuList",
      "skuListColumn1",
      "skuListColumn2",
      "rhBaseH2",
      "rhBaseH3",
      "rhBaseBody1",
      "rhBaseBody2",
      "rhBaseCaption",
      "uppercaseText"
    ]
  });

  const { pageContent } = usePageContent();
  const COMPONENT_ID = "dialog_add_to_cart";

  return (
    <div
      id={`${COMPONENT_ID}_rhr_special_order_confirmation`}
      data-testid={`${COMPONENT_ID}_rhr_special_order_confirmation`}
      key={`${COMPONENT_ID}_rhr_special_order_confirmation`}
      style={{
        minHeight: theme.spacing(44),
        paddingBottom: 80,
        overflow: "hidden"
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            className={classNames([
              typographyClasses.rhBaseH3,
              typographyClasses.uppercaseText,
              "text-center"
            ])}
          >
            {pageContent?.AGREE_TO_SPECIAL_ORDER_TERMS}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={`${typographyClasses.rhBaseBody1}`}
                component="p"
                paragraph
                dangerouslySetInnerHTML={{
                  __html: confirmationText
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                id="rhrSpecialOrderConfirmation_addToCart-btn"
                variant="contained"
                color="primary"
                onClick={onConfirmed}
                fullWidth
                className={"px-[15px] py-[15px] pb-[18px] pt-[17px]"}
              >
                {pageContent?.AGREE_AND_ADD_TO_CART}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <Typography className={`${typographyClasses.rhBaseBody1} pt-9 pl-3`}>
            {pageContent?.ONE_SPECIAL_ORDER}
          </Typography>
        </div>
        <div className={"bg-[#DDDDDD] bg-opacity-0 h-[3px] w-full mt-2 mb-3"}>
          <Divider />
        </div>
        <Grid item xs={12}>
          <Typography
            className={classNames([
              typographyClasses.rhBaseBody2,
              typographyClasses.uppercaseText
            ])}
            dangerouslySetInnerHTML={{
              __html: productDisplayName
            }}
          ></Typography>
        </Grid>
        <Grid item xs={12} className={"pt-1 px-3"}>
          {fullSkuId && (
            <Grid item container spacing={2} key={`item-fullSkuId`}>
              <Grid item xs={3}>
                <Typography
                  className={classNames(
                    typographyClasses.skuList,
                    typographyClasses.skuListColumn1,
                    "!text-[#999999]"
                  )}
                >
                  {pageContent?.ITEM_NUMBER}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  className={classNames(
                    typographyClasses.skuList,
                    typographyClasses.skuListColumn2,
                    "!text-[#666666]"
                  )}
                >
                  {fullSkuId}
                </Typography>
              </Grid>
            </Grid>
          )}

          {options &&
            options.map((option, index) => (
              <Grid
                item
                container
                spacing={2}
                key={`productOption-${option.value}`}
              >
                <Grid item xs={3}>
                  <Typography
                    className={classNames(
                      typographyClasses.skuList,
                      typographyClasses.skuListColumn1,
                      "!text-[#999999]"
                    )}
                  >
                    {option.type}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    className={classNames(
                      typographyClasses.skuList,
                      typographyClasses.skuListColumn2,
                      "!text-[#666666]"
                    )}
                  >
                    {he.decode(option?.value || "")}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          {qty && (
            <Grid item container spacing={2} key={`item-quantity`}>
              <Grid item xs={3}>
                <Typography
                  className={classNames(
                    typographyClasses.skuList,
                    typographyClasses.skuListColumn1,
                    "!text-[#999999]"
                  )}
                >
                  {pageContent?.QTY}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  className={classNames(
                    typographyClasses.skuList,
                    typographyClasses.skuListColumn2,
                    "!text-[#666666]"
                  )}
                >
                  {qty}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

RHRSpecialOrderConfirmation.defaultProps = {};

export default RHRSpecialOrderConfirmation;
