import React, { FC } from "react";
import {
  makeStyles,
  createStyles,
  Modal,
  IconButton
} from "@RHCommerceDev/utils/material-ui-core";
import { ModalProps } from "@material-ui/core/Modal";
import RHCloseIcon from "@RHCommerceDev/icon-close";
import Promo from "@RHCommerceDev/component-promo";

const useStyles = makeStyles(
  createStyles({
    root: {
      position: "absolute",
      top: "30%",
      left: "50%",
      width: "1000px",
      transform: "translate(-50%, -30%)",
      "&:focus": {
        outline: "none"
      }
    },
    closeInside: {
      position: "absolute",
      top: 20,
      right: 20,
      width: 24,
      height: 24,
      cursor: "pointer",
      zIndex: 1
    }
  })
);

export interface PromosDialogDesktopProps extends Omit<ModalProps, "children"> {
  promo: PromoDisplay;
  onEnd?: () => void;
}

export const PromosDialogDesktop: FC<PromosDialogDesktopProps> = ({
  promo,
  open,
  onClose,
  onEnd
}) => {
  const classes = useStyles();

  return (
    <Modal
      id="promo-dialog-desktop"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={event => onClose?.(event, "escapeKeyDown")}
    >
      <div className={classes.root}>
        <IconButton
          disableRipple
          className={classes.closeInside}
          onClick={event => onClose?.(event, "escapeKeyDown")}
        >
          <RHCloseIcon />
        </IconButton>
        <div id="simple-modal-description">
          <Promo data={promo} onEnd={onEnd} />
        </div>
      </div>
    </Modal>
  );
};

export default PromosDialogDesktop;
