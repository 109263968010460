import React, { FC, useState, useEffect } from "react";
import { useMutation, MutationFunctionOptions } from "@apollo/client";
import { useSensitiveQuery } from "@RHCommerceDev/hooks/useSensitiveQuery";
import { DialogProps } from "@material-ui/core/Dialog";
import Form from "./Form";
import Confirmation from "./Confirmation";
import { mutationRequestTradeService } from "@RHCommerceDev/graphql-client/queries/send-trade-request";
import { ExecutionResult } from "graphql";
import { querySessionConf } from "@RHCommerceDev/graphql-client/queries/session-conf";
import FormDialog from "@RHCommerceDev/layout-form-dialog";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";

const STATE_FORM = "TRADE_REQUEST_DIALOG_STATE_FORM";
const STATE_CONFIRMATION = "TRADE_REQUEST_DIALOG_STATE_CONFIRMATION";

export interface Content {
  heading?: {
    text: string;
  };
  title?: {
    text: string;
  };
  placeholder?: {
    text: string;
  };
  success?: {
    text: string;
  };
  successHeading?: {
    text: string;
  };
}

export interface TradeRequestDialogProps {
  submitRequest: (
    options?: MutationFunctionOptions<any, TradeServiceRequestInput>
  ) => Promise<ExecutionResult<any>>;
  onDoneClicked?: (
    event: React.MouseEvent,
    reason: "backdropClick" | "escapeKeyDown"
  ) => void;
  sessionConfirmation: string;
  loading?: boolean;
}

export const TradeRequestDialogContent: FC<TradeRequestDialogProps> = ({
  submitRequest,
  onDoneClicked,
  loading
}) => {
  const [state, setState] = useState(STATE_FORM);
  const [submitError, setSubmitError] = useState<string>("");

  const { pageContent } = useFetchModel(`form-container`, true);

  useEffect(() => {
    analyticsLoader(a =>
      a.emitAnalyticsEvent(
        document.querySelector("#spa-root > *")! as HTMLElement,
        a.EVENTS.TRADE_REQUEST_CLICK.INT_TYPE
      )
    );
  }, []);

  switch (state) {
    case STATE_FORM:
      return (
        <Form
          submitRequest={onSubmit}
          submitError={submitError}
          loading={loading}
          placeholder={pageContent?.TRADE_REQUEST_FORM?.PLACEHOLDER}
          title={pageContent?.TRADE_REQUEST_FORM?.TITLE}
          heading={pageContent?.TRADE_REQUEST_FORM?.HEADING}
          pageContent={pageContent}
        />
      );
    case STATE_CONFIRMATION:
      return (
        <Confirmation
          onDoneClicked={onDoneClicked}
          success={`<br>${pageContent?.TRADE_REQUEST_FORM?.SUCCESS_TEXT}`}
          successHeading={pageContent?.TRADE_REQUEST_FORM?.SUCCESS_HEADING}
        />
      );
    default:
      return null;
  }

  async function onSubmit(formState: TradeServiceRequestInput) {
    const { data, errors } = await submitRequest({
      variables: formState
    });

    if (data?.requestTradeService?.statusCode === 200) {
      setState(STATE_CONFIRMATION);
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.TRADE_REQUEST.INT_TYPE,
          {
            trade: {
              preferredContact: formState.preferredContact,
              //@ts-ignore
              message: formState.description
            }
          }
        )
      );
    }

    if (errors) {
      setSubmitError(pageContent?.TRADE_REQUEST_FORM?.DEFAULT_ERROR);
    }
  }
};

export default (props: Omit<DialogProps, "children">) => {
  const { data: { sessionConf } = {} as Query } = useSensitiveQuery<Query>(
    querySessionConf,
    {
      fetchPolicy: "no-cache",
      skip: !props.open
    }
  );

  const [sendTradeRequest, { loading }] = useMutation<Mutation>(
    mutationRequestTradeService,
    {
      context: {
        fetchOptions: {
          method: "POST"
        }
      }
    }
  );

  return (
    <FormDialog {...props} onClose={props.onClose}>
      <TradeRequestDialogContent
        submitRequest={sendTradeRequest}
        onDoneClicked={props.onClose}
        sessionConfirmation={sessionConf?.sessionConfirmation ?? ""}
        loading={loading}
      />
    </FormDialog>
  );
};
